import React from "react"
import * as Icon from "react-feather"
import { ReactComponent as Delivered } from "../assets/img/prod_steps/livre.svg"
import { ReactComponent as InSupply } from "../assets/img/prod_steps/en_appro.svg"
import { ReactComponent as WaitingDelivery } from "../assets/img/prod_steps/en_attente_livraison.svg"
import { ReactComponent as InDelivery } from "../assets/img/prod_steps/en_livraison.svg"
import { ReactComponent as InProd } from "../assets/img/prod_steps/en_prod.svg"
import { Roles } from "../helpers/enum"

const navigationConfig = (user) => {
  return !user.isInternUser ?
    [//Clients
      {
        id: "accueil",
        title: "Accueil",
        type: "item",
        icon: <Icon.Home size={20} />,
        navLink: "/"
      },
      {
        type: "groupHeader",
        groupTitle: "Informations"
      },
      {
        id: "consulter_documents",
        title: "Documents",
        type: "collapse",
        icon: <Icon.BookOpen size={20} />,
        children: [
          {
            id: "doc-catalogue",
            title: "Catalogues",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=catalogues"
          },
          {
            id: "doc-info-produits",
            title: "Info. produits",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=info_produits"
          },
          {
            id: "doc-plan",
            title: "Plans",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=plans"
          },
          {
            id: "doc-pv-essais",
            title: "PV d'essais",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=pv_essais"
          },
          {
            id: "doc-bon-commande",
            title: "Bons de commande",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=bon_de_commande"
          },
          {
            id: "doc-tarif",
            title: "Tarifs",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=tarifs"
          },
          {
            id: "doc-boite-outil",
            title: "Boîte à outils",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=boite_a_outils"
          },
          {
            id: "doc-info-legale",
            title: "Info. légales",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=info_legales"
          },
          {
            id: "doc-cgv",
            title: "CGV",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=cgv"
          },
          {
            id: "doc-men-combes",
            title: "Menuiseries Combes",
            type: "item",
            icon: <Icon.Circle size={12} />,
            navLink: "/documents_clients?dossier=menuiseries_combes"
          },
        ]
      },
      {
        id: "delais_livraison",
        title: "Délais de livraison",
        type: "item",
        icon: <Icon.Truck fill="currentColor" width={20} height={20} />,
        navLink: "/delais_livraison"
      },
      {
        type: "groupHeader",
        groupTitle: "COMMANDES"
      },
      {
        id: "commandes_en_cours",
        title: "En cours",
        type: "item",
        icon: <InSupply fill="currentColor" width={20} height={20} />,
        navLink: "/commandes/en_cours"
      },
      {
        id: "commandes_livrees",
        title: "Livrées",
        type: "item",
        icon: <InDelivery fill="currentColor" width={20} height={20} />,
        navLink: "/commandes/livrees"
      },
      {
        id: "commandes_archivees",
        title: "Archivées",
        type: "item",
        icon: <Delivered fill="currentColor" width={20} height={20} />,
        navLink: "/commandes/archivees"
      },
    ] :
    user.roleId === Roles.ExecutiveAssistant || user.roleId === Roles.Admin ?
      [//Assistant de direction
        {
          id: "comptes_clients",
          title: "Comptes clients",
          type: "item",
          icon: <Icon.User fill="currentColor" width={20} height={20} />,
          navLink: "/comptes_clients"
        },
        {
          type: "groupHeader",
          groupTitle: "Informations"
        },
        {
          id: "consulter_documents",
          title: "Doc. Clients",
          type: "collapse",
          icon: <Icon.BookOpen size={20} />,
          children: [
            {
              id: "doc-catalogue",
              title: "Catalogues",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=catalogues"
            },
            {
              id: "doc-info-produits",
              title: "Info. produits",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=info_produits"
            },
            {
              id: "doc-plan",
              title: "Plans",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=plans"
            },
            {
              id: "doc-pv-essais",
              title: "PV d'essais",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=pv_essais"
            },
            {
              id: "doc-bon-commande",
              title: "Bons de commande",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=bon_de_commande"
            },
            {
              id: "doc-tarif",
              title: "Tarifs",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=tarifs"
            },
            {
              id: "doc-boite-outil",
              title: "Boîte à outils",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=boite_a_outils"
            },
            {
              id: "doc-info-legale",
              title: "Info. légales",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=info_legales"
            },
            {
              id: "doc-cgv",
              title: "CGV",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=cgv"
            },
            {
              id: "doc-men-combes",
              title: "Menuiseries Combes",
              type: "item",
              icon: <Icon.Circle size={12} />,
              navLink: "/documents_clients?dossier=menuiseries_combes"
            },
          ]
        },
        {
          id: "delais_livraison",
          title: "Délais de livraison",
          type: "item",
          icon: <Icon.Truck fill="currentColor" width={20} height={20} />,
          navLink: "/delais_livraison"
        },
        {
          type: "groupHeader",
          groupTitle: "MES CLIENTS"
        },
        {
          id: "mes_clients",
          title: "Consulter",
          type: "item",
          icon: <InSupply fill="currentColor" width={20} height={20} />,
          navLink: "/clients/mes_clients"
        },
      ]
      :
      user.roleId === Roles.Marketing ?
        [//Marketing
          {
            id: "comptes_clients",
            title: "Comptes clients",
            type: "item",
            icon: <Icon.User fill="currentColor" width={20} height={20} />,
            navLink: "/comptes_clients"
          },
          {
            type: "groupHeader",
            groupTitle: "Informations"
          },
          {
            id: "consulter_documents",
            title: "Doc. Clients",
            type: "collapse",
            icon: <Icon.BookOpen size={20} />,
            children: [
              {
                id: "doc-catalogue",
                title: "Catalogues",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=catalogues"
              },
              {
                id: "doc-info-produits",
                title: "Info. produits",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=info_produits"
              },
              {
                id: "doc-plan",
                title: "Plans",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=plans"
              },
              {
                id: "doc-pv-essais",
                title: "PV d'essais",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=pv_essais"
              },
              {
                id: "doc-bon-commande",
                title: "Bons de commande",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=bon_de_commande"
              },
              {
                id: "doc-tarif",
                title: "Tarifs",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=tarifs"
              },
              {
                id: "doc-boite-outil",
                title: "Boîte à outils",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=boite_a_outils"
              },
              {
                id: "doc-info-legale",
                title: "Info. légales",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=info_legales"
              },
              {
                id: "doc-cgv",
                title: "CGV",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=cgv"
              },
              {
                id: "doc-men-combes",
                title: "Menuiseries Combes",
                type: "item",
                icon: <Icon.Circle size={12} />,
                navLink: "/documents_clients?dossier=menuiseries_combes"
              },
            ]
          },
          {
            id: "delais_livraison",
            title: "Délais de livraison",
            type: "item",
            icon: <Icon.Truck fill="currentColor" width={20} height={20} />,
            navLink: "/delais_livraison"
          },
          {
            type: "groupHeader",
            groupTitle: "MES CLIENTS"
          },
          {
            id: "mes_clients",
            title: "Consulter",
            type: "item",
            icon: <InSupply fill="currentColor" width={20} height={20} />,
            navLink: "/clients/mes_clients"
          }
        ] :
        user.roleId === Roles.CustomerSupport  ?
          [//Accueil
            {
              type: "groupHeader",
              groupTitle: "Informations"
            },
            {
              id: "delais_livraison",
              title: "Délais de livraison",
              type: "item",
              icon: <Icon.Truck fill="currentColor" width={20} height={20} />,
              navLink: "/delais_livraison"
            },
            {
              id: "consulter_documents",
              title: "Doc. Clients",
              type: "collapse",
              icon: <Icon.BookOpen size={20} />,
              children: [
                {
                  id: "doc-catalogue",
                  title: "Catalogues",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=catalogues"
                },
                {
                  id: "doc-info-produits",
                  title: "Info. produits",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=info_produits"
                },
                {
                  id: "doc-plan",
                  title: "Plans",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=plans"
                },
                {
                  id: "doc-pv-essais",
                  title: "PV d'essais",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=pv_essais"
                },
                {
                  id: "doc-bon-commande",
                  title: "Bons de commande",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=bon_de_commande"
                },
                {
                  id: "doc-tarif",
                  title: "Tarifs",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=tarifs"
                },
                {
                  id: "doc-boite-outil",
                  title: "Boîte à outils",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=boite_a_outils"
                },
                {
                  id: "doc-info-legale",
                  title: "Info. légales",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=info_legales"
                },
                {
                  id: "doc-cgv",
                  title: "CGV",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=cgv"
                },
                {
                  id: "doc-men-combes",
                  title: "Menuiseries Combes",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=menuiseries_combes"
                },
              ]
            },
            {
              type: "groupHeader",
              groupTitle: "MES CLIENTS"
            },
            {
              id: "mes_clients",
              title: "Consulter",
              type: "item",
              icon: <InSupply fill="currentColor" width={20} height={20} />,
              navLink: "/clients/mes_clients"
            },
          ] :
          user.roleId === Roles.Tester ?
          [//Accueil
            {
              type: "groupHeader",
              groupTitle: "Informations"
            },
            {
              id: "delais_livraison",
              title: "Délais de livraison",
              type: "item",
              icon: <Icon.Truck fill="currentColor" width={20} height={20} />,
              navLink: "/delais_livraison"
            },
            {
              id: "consulter_documents",
              title: "Doc. Clients",
              type: "collapse",
              icon: <Icon.BookOpen size={20} />,
              children: [
                {
                  id: "doc-catalogue",
                  title: "Catalogues",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=catalogues"
                },
                {
                  id: "doc-info-produits",
                  title: "Info. produits",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=info_produits"
                },
                {
                  id: "doc-plan",
                  title: "Plans",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=plans"
                },
                {
                  id: "doc-pv-essais",
                  title: "PV d'essais",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=pv_essais"
                },
                {
                  id: "doc-bon-commande",
                  title: "Bons de commande",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=bon_de_commande"
                },
                {
                  id: "doc-tarif",
                  title: "Tarifs",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=tarifs"
                },
                {
                  id: "doc-boite-outil",
                  title: "Boîte à outils",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=boite_a_outils"
                },
                {
                  id: "doc-info-legale",
                  title: "Info. légales",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=info_legales"
                },
                {
                  id: "doc-cgv",
                  title: "CGV",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=cgv"
                },
                {
                  id: "doc-men-combes",
                  title: "Menuiseries Combes",
                  type: "item",
                  icon: <Icon.Circle size={12} />,
                  navLink: "/documents_clients?dossier=menuiseries_combes"
                },
              ]
            },
            {
              type: "groupHeader",
              groupTitle: "MES CLIENTS"
            },
            {
              id: "mes_clients",
              title: "Consulter",
              type: "item",
              icon: <InSupply fill="currentColor" width={20} height={20} />,
              navLink: "/clients/mes_clients"
            },
          ] :
          user.roleId === Roles.Qualite ?
            [//Qualité
              {
                type: "groupHeader",
                groupTitle: "Informations"
              },
              {
                id: "delais_livraison",
                title: "Délais de livraison",
                type: "item",
                icon: <Icon.Truck fill="currentColor" width={20} height={20} />,
                navLink: "/delais_livraison"
              },
              {
                id: "consulter_documents",
                title: "Doc. Clients",
                type: "collapse",
                icon: <Icon.BookOpen size={20} />,
                children: [
                  {
                    id: "doc-catalogue",
                    title: "Catalogues",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=catalogues"
                  },
                  {
                    id: "doc-info-produits",
                    title: "Info. produits",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=info_produits"
                  },
                  {
                    id: "doc-plan",
                    title: "Plans",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=plans"
                  },
                  {
                    id: "doc-pv-essais",
                    title: "PV d'essais",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=pv_essais"
                  },
                  {
                    id: "doc-bon-commande",
                    title: "Bons de commande",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=bon_de_commande"
                  },
                  {
                    id: "doc-tarif",
                    title: "Tarifs",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=tarifs"
                  },
                  {
                    id: "doc-boite-outil",
                    title: "Boîte à outils",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=boite_a_outils"
                  },
                  {
                    id: "doc-info-legale",
                    title: "Info. légales",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=info_legales"
                  },
                  {
                    id: "doc-cgv",
                    title: "CGV",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=cgv"
                  },
                  {
                    id: "doc-men-combes",
                    title: "Menuiseries Combes",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=menuiseries_combes"
                  },
                ]
              },
            ] :
            [//Equipe commerciale
              {
                type: "groupHeader",
                groupTitle: "MES CLIENTS"
              },
              {
                id: "mes_clients",
                title: "Consulter",
                type: "item",
                icon: <InSupply fill="currentColor" width={20} height={20} />,
                navLink: "/clients/mes_clients"
              },
              {
                type: "groupHeader",
                groupTitle: "Informations"
              },
              {
                id: "consulter_documents",
                title: "Doc. Clients",
                type: "collapse",
                icon: <Icon.BookOpen size={20} />,
                children: [
                  {
                    id: "doc-catalogue",
                    title: "Catalogues",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=catalogues"
                  },
                  {
                    id: "doc-info-produits",
                    title: "Info. produits",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=info_produits"
                  },
                  {
                    id: "doc-plan",
                    title: "Plans",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=plans"
                  },
                  {
                    id: "doc-pv-essais",
                    title: "PV d'essais",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=pv_essais"
                  },
                  {
                    id: "doc-bon-commande",
                    title: "Bons de commande",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=bon_de_commande"
                  },
                  {
                    id: "doc-tarif",
                    title: "Tarifs",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=tarifs"
                  },
                  {
                    id: "doc-boite-outil",
                    title: "Boîte à outils",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=boite_a_outils"
                  },
                  {
                    id: "doc-info-legale",
                    title: "Info. légales",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=info_legales"
                  },
                  {
                    id: "doc-cgv",
                    title: "CGV",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=cgv"
                  },
                  {
                    id: "doc-men-combes",
                    title: "Menuiseries Combes",
                    type: "item",
                    icon: <Icon.Circle size={12} />,
                    navLink: "/documents_clients?dossier=menuiseries_combes"
                  },
                ]
              },
              {
                id: "delais_livraison",
                title: "Délais de livraison",
                type: "item",
                icon: <Icon.Truck fill="currentColor" width={20} height={20} />,
                navLink: "/delais_livraison"
              },
            ]
}


export default navigationConfig
